<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmTprepSessionFormData && Object.keys(vmTprepSessionFormData).length > 0">
              <div class="form-row">

  <div class="col-md-12 mb-3">
    <label for="validationquiz_count">
      {{cvQuizCountLabel}}</label
    >
    <input
      v-model="vmTprepSessionFormData.quiz_count"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationquiz_id_list">
      {{cvQuizIdListLabel}}</label
    >
    <input
      v-model="vmTprepSessionFormData.quiz_id_list"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationpay_tid">
      {{cvPayTidLabel}}</label
    >
    <input
      v-model="vmTprepSessionFormData.pay_tid"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationaff_code">
      {{cvAffCodeLabel}}</label
    >
    <input
      v-model="vmTprepSessionFormData.aff_code"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationaff_code_allocator">
      {{cvAffCodeAllocatorLabel}}</label
    >
    <input
      v-model="vmTprepSessionFormData.aff_code_allocator"
      type="text"
      class="form-control"
      required
    />
</div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="tprep_sessionEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { TprepSessions } from "../../../FackApi/api/TprepSession"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "TprepSessionEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propTprepSessionObj: {
      type: Object,
      default: function () {
        return {
          "quiz_count": "",
          "quiz_id_list": "",
          "pay_tid": "",
          "aff_code": "",
          "aff_code_allocator": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "tprep_session_edit",
      cvCardTitle: "Edit tprep_session",
      cvCardSubHeader: "Edit tprep_session sub header",
      cvSubmitBtn: "Edit",
      cvQuizCountLabel: "quiz count",
      cvQuizIdListLabel: "quiz id list",
      cvPayTidLabel: "pay tid",
      cvAffCodeLabel: "aff code",
      cvAffCodeAllocatorLabel: "aff code allocator",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "TprepSession Updated",
      vmTprepSessionFormData: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.tprep_sessionView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmTprepSessionFormData) {
          if (!this.vmTprepSessionFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * tprep_sessionView
     */
    async tprep_sessionView () {
      try {
        if (this.propOpenedInModal) {
          this.vmTprepSessionFormData = this.propTprepSessionObj
        }
        else {
          let tprepSessionId = this.$route.params.tps_id
          let tprepSessionViewResp = await TprepSessions.tprep_sessionView(this, tprepSessionId)

          if (tprepSessionViewResp && tprepSessionViewResp.resp_status) {
            this.vmTprepSessionFormData = tprepSessionViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at tprep_sessionView() and Exception:", err.message)
      }
    },
    /**
     * tprep_sessionEdit
     */
    async tprep_sessionEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let tprepSessionAddResp = await TprepSessions.tprep_sessionEdit(this, this.vmTprepSessionFormData)
        await ApiResponse.responseMessageDisplay(this, tprepSessionAddResp)

        if (tprepSessionAddResp && !tprepSessionAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseTprepSessionEditModal", this.vmTprepSessionFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at tprep_sessionEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
